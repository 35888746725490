import { FC } from "react";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logo from "../../../assets/images/trustfinance-logo.svg";
import { ReachClick } from "../../../utils/core";
import { fnRatingDisplay } from "../../../components/rateDisplay";
import StarRate from "../../../components/StarRate";

const WidgetTemplate13: FC<IWidgetTemplate> = ({
  company,
  score,
  review,
  theme,
}) => {
  const payload = {
    companyId: company?.data?.companyId,
    widgetId: 8,
  };
  return (
    <div className="overflow-hidden d-flex justify-content-center fs-12">
      <a
        className="text-decoration-none"
        style={{ color: theme === "dark" ? "white" : "unset" }}
        href={`https://www.trustfinance.com/companies/${company?.data?.companyId}?utm_source=widget&utm_medium=banner&utm_campaign=micro-star-vertical`}
        target="_blank"
        rel="noreferrer noopener"
        id={"Tooltip-" + 1}
        onClick={() => ReachClick(payload)}
      >
        <div className=" d-flex align-items-center flex-column ">
          <div className="d-flex align-items-center">
            <b
              className={`d-none d-md-block ${
                theme === "dark" ? "text-white" : ""
              }`}
            >
              {fnRatingDisplay(score?.score)}
            </b>{" "}
            <div className="d-flex align-items-center txt ">
              <img src={logo} alt="" width={24} height={24} />
              <span className="fs-12 ">TrustFinance</span>
            </div>
          </div>
          <div className="fs-16 d-flex align-items-center p-2 fw-bold">
            <StarRate value={score?.score} size={24} />
          </div>
          <div
            className={`text-center ${
              theme === "dark" ? "text-white" : "text-tfn-gray"
            } `}
          >
            TrusScore{" "}
            <b className={`${theme === "dark" ? "text-white" : "text-black"}`}>
              {score?.score}
            </b>{" "}
            |{" "}
            <b className={`${theme === "dark" ? "text-white" : "text-black"}`}>
              {score?.totalReview}
            </b>{" "}
            reviews
          </div>
        </div>{" "}
      </a>{" "}
    </div>
  );
};

export default WidgetTemplate13;
