import { AxiosResponse } from "axios";
import { ICompany, ICompanyInfo } from "../interfaces/company";
import { ICompanyType } from "../interfaces/companyV2";
import { IReview } from "../interfaces/review";
import { IScore } from "../interfaces/score";

import { http } from "../utils/http";

export const getCompanyByIdApi = async (
  id: string
): Promise<AxiosResponse<ICompany>> => {
  return await http.get(`/companies/${id}`);
};
export const getCompanyInfoByIdApi = async (
  id: string
): Promise<AxiosResponse<ICompanyInfo>> => {
  return await http.get(`/company-info/${id}`);
};
export const getCompanyInfoV2Api = async (): Promise<
  AxiosResponse<ICompanyType>
> => {
  return await http.get("/widgets/information/company", "", true);
};
export const getScoreByIdApi = async (
  id: string
): Promise<AxiosResponse<IScore>> => {
  return await http.get(`/company/${id}/trust-score`);
};
export const getReviewByCompanyIdApi = async (
  id: string
): Promise<AxiosResponse<IReview>> => {
  return await http.get(
    `/company/${id}/review?order=DESC&page=1&limit=4&orderBy=createdAt&filterBy=status&filter=2`
  );
};
