import React, {
  FC,
  memo,
  PointerEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import _ from "lodash";
import shortid from "shortid";

interface StarRateProps {
  value?: number;
  size?: number;
  selectable?: boolean;
  onChange?: (value: number) => void;
}

interface StarProps {
  size: number;
  color?: string;
}

const StarFull: FC<StarProps> = ({ size, color = "#4060ff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <g transform="translate(2.98 3.616)">
      <rect
        width="24"
        height="24"
        rx="5"
        transform="translate(-2.98 -3.616)"
        fill={color}
      />
      <path
        d="M7.265,6.739,10.036,1.13,12.8,6.741l6.19.9-4.481,4.366,1.056,6.165L10.031,15.26,4.494,18.17,5.553,12,1.075,7.636Z"
        transform="translate(-1.014 -1.187)"
        fill="#fff"
      />
      <path
        d="M5.538,8.509,0,11.419v-.007L8.378,0,14.5.891,10.018,5.256l1.057,6.166Z"
        transform="translate(3.48 5.564)"
        fill="#e9edf5"
      />
      <path
        d="M5.521,4.27,0,7.171,5.256,0l5.8,7.149.006.034Z"
        transform="translate(3.496 9.803)"
        fill="#c8cfde"
      />
    </g>
  </svg>
);
const StarHalf: FC<StarProps> = ({ size, color = "#4060ff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_14"
          data-name="Rectangle 14"
          width="12"
          height="24"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Star" transform="translate(2.98 3.616)">
      <g
        id="Mask_Group_3"
        data-name="Mask Group 3"
        transform="translate(-2.98 -3.616)"
        clipPath="url(#clip-path)"
      >
        <rect
          id="Rectangle_13"
          data-name="Rectangle 13"
          width="24"
          height="24"
          rx="5"
          fill={color}
        />
      </g>
      <g
        id="Mask_Group_2"
        data-name="Mask Group 2"
        transform="translate(9.02 -3.616)"
        clipPath="url(#clip-path)"
      >
        <rect
          id="Rectangle_12"
          data-name="Rectangle 12"
          width="24"
          height="24"
          rx="5"
          transform="translate(-12)"
          fill="#d5dae2"
        />
      </g>
      <path
        id="Path_5"
        data-name="Path 5"
        d="M7.265,6.739,10.036,1.13,12.8,6.741l6.19.9-4.481,4.366,1.056,6.165L10.031,15.26,4.494,18.17,5.553,12,1.075,7.636Z"
        transform="translate(-1.014 -1.187)"
        fill="#fff"
      />
      <path
        id="Intersection_1"
        data-name="Intersection 1"
        d="M5.538,8.509,0,11.419v-.007L8.378,0,14.5.891,10.018,5.256l1.057,6.166Z"
        transform="translate(3.48 5.564)"
        fill="#e9edf5"
      />
      <path
        id="Intersection_2"
        data-name="Intersection 2"
        d="M5.521,4.27,0,7.171,5.256,0l5.8,7.149.006.034Z"
        transform="translate(3.496 9.803)"
        fill="#c8cfde"
      />
    </g>
  </svg>
);
const Star: FC<StarProps> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <g id="Star" transform="translate(2.98 3.616)">
      <rect
        id="Rectangle_10"
        data-name="Rectangle 10"
        width="24"
        height="24"
        rx="5"
        transform="translate(-2.98 -3.616)"
        fill="#d5dae2"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M7.173,6.655,9.9,1.13l2.726,5.527,6.1.888-4.414,4.3,1.041,6.073L9.9,15.049,4.443,17.915l1.043-6.073-4.411-4.3Z"
        transform="translate(-1.059 -1.24)"
        fill="#fff"
      />
      <path
        id="Intersection_1"
        data-name="Intersection 1"
        d="M5.455,8.382,0,11.249v-.007L8.253,0l6.03.878-4.414,4.3,1.041,6.073Z"
        transform="translate(3.383 5.427)"
        fill="#e9edf5"
      />
      <path
        id="Intersection_2"
        data-name="Intersection 2"
        d="M5.439,4.206,0,7.064,5.178,0l5.71,7.042.006.033Z"
        transform="translate(3.399 9.603)"
        fill="#c8cfde"
      />
    </g>
  </svg>
);

const StarRate: FC<StarRateProps> = memo(
  ({ value = 0, size = 24, selectable = false, onChange = () => null }) => {
    const max = 5;
    const rate = useMemo(() => {
      let rounded = 0;
      if (value < 1.5 && value > 0) {
        rounded = 1;
      } else {
        let mod = (value % 1) * 10;
        const num = Math.floor(value);
        if (mod > 0) {
          if (mod < 5) mod = 0;
          else mod = 0.5;
        }
        rounded = num + mod;
      }

      return rounded;
    }, [value]);
    const [count, setCount] = useState<number>(value);

    const updateCount = (newCount: number) => {
      setCount((prev) => (prev !== newCount ? newCount : prev));
    };

    const onPointerUp = (e: PointerEvent<HTMLDivElement>) => {
      const width = e.currentTarget.clientWidth;
      const offset = e.currentTarget.getBoundingClientRect().x;
      const x = e.pageX;
      const diff = x - offset + size / 2;
      onChange(Math.round((diff * 5) / width));
    };

    const onPointerMove = (e: PointerEvent<HTMLDivElement>) => {
      const width = e.currentTarget.clientWidth;
      const offset = e.currentTarget.getBoundingClientRect().x;
      const x = e.pageX;
      const diff = x - offset + size / 2;
      updateCount(Math.round((diff * 5) / width));
    };

    const onPointerLeave = () => {
      if (value === 0) updateCount(0);
      else updateCount(value);
    };

    useEffect(() => {
      updateCount(value);
    }, [value]);

    const starRateColor = (rate = 1) => {
      if (rate == 1) return "#FF4D5B";
      if (rate == 1.5) return "#FF9B45";
      if (rate == 2) return "#FF9B45";
      if (rate == 2.5) return "#FFBB00";
      if (rate == 3) return "#FFBB00";
      if (rate == 3.5) return "#5596FF";
      if (rate == 4) return "#5596FF";
      if (rate == 4.5) return "#4060FF";
      if (rate == 5) return "#4060ff";
    };

    if (!selectable) {
      return (
        <div className="d-flex gap-x-1 select-none">
          {_.times(max, (i) => {
            const x = i + 1;
            if (x <= rate)
              return (
                <StarFull
                  key={shortid.generate()}
                  size={size}
                  color={starRateColor(rate)}
                />
              );
            if (x - rate === 0.5)
              return (
                <StarHalf
                  key={shortid.generate()}
                  size={size}
                  color={starRateColor(rate)}
                />
              );
            return <Star key={shortid.generate()} size={size} />;
          })}
        </div>
      );
    }

    return (
      <div
        className="d-flex gap-x-1 cursor-pointer"
        style={{ width: "fit-content" }}
        onPointerMove={onPointerMove}
        onPointerLeave={onPointerLeave}
        onPointerUp={onPointerUp}
      >
        {_.times(max, (i) => {
          const x = i + 1;
          if (x <= count)
            return (
              <StarFull
                key={shortid.generate()}
                size={size}
                color={starRateColor(count)}
              />
            );
          return <Star key={shortid.generate()} size={size} />;
        })}
      </div>
    );
  }
);

StarRate.displayName = "StarRate";

export default StarRate;
