import React, { FC } from "react";
import {
  fnRatingDisplay,
  fnRatingDisplayColor,
} from "../../../components/rateDisplay";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logo from "../../../assets/images/trustfinance-logo.svg";
import StarRate from "../../../components/StarRate";
import moment from "moment";

const WidgetTemplate14: FC<IWidgetTemplate> = ({
  company,
  score,
  review,
  theme,
}) => {
  const year = moment().format("yyyy");

  const colorText = (status: string): string => {
    switch (status?.toLowerCase()) {
      case "recommended":
        return "bg-company-status-recommend text-company-status-recommend";
      case "regulated":
        return "bg-company-status-regulated text-company-status-regulated";
      case "non-regulated":
        return "bg-company-status-non-regulated text-company-status-non-regulated";
      case "rebrand":
        return "bg-company-status-rebrand text-company-status-rebrand";
      case "warning":
        return "bg-company-status-warning text-company-status-warning";
      default:
        return "";
    }
  };
  const handleCalculateTrafficStatus = (traffic: number) => {
    if (traffic >= 3.81) {
      return { message: "excellent", textColor: "#4060FF" };
    } else if (traffic >= 2.01 && traffic <= 3.8) {
      return { message: "average", textColor: "#FFBB00" };
    } else if (traffic >= 0.0 && traffic <= 2.0) {
      return { message: "bad", textColor: "#FF4D5B" };
    } else {
      return { message: "#FF4D5B" };
    }
  };
  const handleCalculateLicenseStatus = (license: number) => {
    if (license >= 0.0 && license <= 2.0) {
      return { message: "non-regulated", colorText: "#FF1A45" };
    } else if (license >= 2.01 && license <= 5.0) {
      return { message: "regulated", colorText: "#00AF80" };
    }
  };
  const renderFoundation = (item: any) => {
    if (item.foundedType) {
      if (item.foundedType === "Day")
        return moment(item.founded ?? item?.foundedAt).format("DD MMM YYYY");
      if (item.foundedType === "Month")
        return moment(item.founded ?? item?.foundedAt).format("MMM YYYY");
      if (item.foundedType === "Year")
        return moment(item.founded ?? item?.foundedAt).format("YYYY");
      if (item.foundedType === "unknown") return "N/A";
    } else if (item.foundedAt) {
      return moment(item.foundedAt).format("YYYY");
    } else if (item.foundation) {
      return item.foundation;
    } else {
      return "N/A";
    }
  };

  return (
    <div
      className={`overflow-hidden d-flex justify-content-center fs-12 p-2 ${
        theme === "dark" ? "text-white" : ""
      }`}
      style={{ background: theme === "dark" ? "black" : "#F5F5F8" }}
    >
      <a
        className="text-decoration-none"
        style={{ color: "unset" }}
        href={`https://www.trustfinance.com/companies/${company?.id}?utm_source=widget&utm_medium=banner&utm_campaign=full-profile`}
        target="_blank"
        rel="noreferrer noopener"
        id={"Tooltip-" + 1}
        // onClick={() => ReachClick(payload)}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div
            className={`${
              theme === "dark" ? "" : "border border-2 rounded-3"
            } d-flex`}
            style={{ height: 32, width: 32 }}
          >
            {company.picture ? (
              <img
                alt={company?.name}
                src={company.picture}
                width={28}
                className="rounded-2"
                style={{ objectFit: "contain" }}
              />
            ) : (
              <div
                className={`rounded-2 d-flex justify-content-center align-items-center fs-16 fw-semibold bg-white ${
                  theme === "dark" ? "text-black" : ""
                }`}
                style={{ width: 32 }}
              >
                {company?.name[0]}
              </div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <p className="fw-semibold ms-2 mb-0 fs-16 line-clamp-1 company-name-widget-14">
              {company?.name}
            </p>
            {company?.isClaimed && (
              <svg
                className="flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
              >
                <path
                  d="M13.2808 3.91383C14.6086 3.34371 15.9214 4.76151 15.2462 6.04429C14.8261 6.85446 15.1862 7.84467 16.0339 8.18975C17.3767 8.72986 17.3017 10.6578 15.9214 11.0854C15.0512 11.3554 14.6086 12.3231 14.9612 13.1558C15.5313 14.4836 14.1135 15.7964 12.8307 15.1212C12.0205 14.7011 11.0303 15.0612 10.6853 15.9089C10.1451 17.2517 8.21723 17.1767 7.78963 15.7964C7.51958 14.9262 6.55187 14.4836 5.71919 14.8362C4.39141 15.4063 3.07863 13.9885 3.75377 12.7057C4.17386 11.8955 3.81379 10.9053 2.9661 10.5603C1.62332 10.0201 1.69833 8.09223 3.07863 7.66463C3.94881 7.39458 4.39141 6.42687 4.03883 5.59419C3.46871 4.26641 4.88651 2.95363 6.16929 3.62877C6.97946 4.04886 7.96967 3.68879 8.31475 2.8411C8.85486 1.49832 10.7828 1.57333 11.2104 2.95363C11.4804 3.82381 12.4481 4.26641 13.2808 3.91383Z"
                  fill="#4060FF"
                />
                <path
                  d="M12.2832 7.58221C12.1632 7.46218 11.9607 7.46218 11.8406 7.58221L10.6704 8.75246L8.8775 10.5453C8.75747 10.6654 8.56243 10.6654 8.4349 10.5453L7.15963 9.27007C7.0396 9.15004 6.83706 9.15004 6.71703 9.27007C6.59701 9.39009 6.59701 9.59264 6.71703 9.71266L7.99231 10.9879C8.17234 11.168 8.4124 11.2655 8.65245 11.2655C8.8925 11.2655 9.13255 11.1755 9.31259 10.9954L11.1055 9.20255L12.2757 8.0323C12.3957 7.91228 12.4032 7.70223 12.2832 7.58221Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.75"
                  strokeMiterlimit="10"
                />
              </svg>
            )}
          </div>
          {company?.companyStatus?.length > 0 && (
            <>
              <div className="d-none d-sm-flex d-lg-none gap-1">
                {company?.companyStatus?.slice(0, 1).map((status) => (
                  <div
                    key={status.id}
                    className={`text-uppercase px-2 ms-1 rounded-1 fw-semibold d-flex ${colorText(
                      status?.status
                    )}`}
                  >
                    {status?.status}
                  </div>
                ))}
                {company?.companyStatus?.length > 1 && (
                  <p className="mb-0 fs-12">
                    +{company?.companyStatus?.length - 1}
                  </p>
                )}
              </div>

              <div className="d-none d-lg-flex gap-1">
                {company?.companyStatus?.map((status) => (
                  <div
                    key={status.id}
                    className={`text-uppercase px-2 ms-1 rounded-1 fw-semibold d-flex ${colorText(
                      status?.status
                    )}`}
                  >
                    {status?.status}
                  </div>
                ))}
              </div>
            </>
          )}

          <div id="foundedIn" className="d-flex mx-2 align-items-center gap-2">
            {company?.foundedIn?.picture && (
              <img
                className="img-country"
                width={16}
                height={16}
                alt={company?.foundedIn?.name}
                src={company?.foundedIn?.picture}
              />
            )}

            <p className="mb-0 fs-12">
              {company?.foundedIn?.name
                ? `${company?.foundedIn?.name}${company?.foundedAt ? "," : ""}`
                : ""}{" "}
              {company?.foundedAt
                ? `${renderFoundation(company)} (${
                    parseInt(year) - +moment(company.foundedAt).format("YYYY")
                  } Years)`
                : ""}
            </p>
          </div>
          {company.licenses.length > 0 && (
            <>
              <div className="divide-bar" />
              <div className="show-license-mobile-tablet mx-2 gap-1">
                License:{" "}
                {company?.licenses
                  ?.filter((license) => license?.shortName !== null)
                  .slice(0, 1)
                  .map((item) => (
                    <div
                      className="px-2 ms-1 rounded-1 fw-semibold"
                      style={{
                        backgroundColor:
                          theme === "dark" ? "#00AF80" : "#DDEEEA",
                      }}
                    >
                      {item?.shortName}
                    </div>
                  ))}
                {company?.licenses?.length > 1 && (
                  <p className="mb-0 fs-12">+{company?.licenses?.length - 1}</p>
                )}
              </div>
              <div className="show-license-desktop mx-2 gap-1">
                License:{" "}
                {company?.licenses
                  ?.filter((license) => license?.shortName !== null)
                  .slice(0, 3)
                  .map((item) => (
                    <div
                      className="px-2 ms-1 rounded-1 fw-semibold"
                      style={{
                        backgroundColor:
                          theme === "dark" ? "#00AF80" : "#DDEEEA",
                      }}
                    >
                      {item?.shortName}
                    </div>
                  ))}
                {company?.licenses?.length > 3 && (
                  <p className="mb-0 fs-12">+{company?.licenses?.length - 3}</p>
                )}
              </div>
            </>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-center flex-wrap">
          <b
            style={{ color: `${fnRatingDisplayColor(company?.rate?.score)}` }}
            className="text-uppercase fs-14"
          >
            {fnRatingDisplay(company?.rate?.score)}
          </b>
          <div className=" d-flex align-items-center p-2 fw-bold">
            <StarRate value={company?.rate?.score} size={14} />
          </div>
          <div className="text-center fs-10" style={{ color: "#858693" }}>
            <b
              className={`fs-14 ${
                theme === "dark" ? "text-white" : "text-black"
              }`}
            >
              {company?.rate?.score}
            </b>
            /5 based on <b>{company?.rate?.totalReview}</b> reviews
          </div>
          <div className="divide-bar mx-2" />
          <div id="verification" className="d-flex gap-2 align-items-center">
            <p className="mb-0 fs-10">Verification:</p>
            <p
              style={{
                color: `${company.verification ? "#00AF6C" : "#ff0000"}`,
              }}
              className="mb-0 fs-10 fw-semibold"
            >
              {company.verification ? "Verified" : "Unverified"}
            </p>
          </div>
          {company?.licenseScore !== null && (
            <>
              <div className="divide-dot mx-2" />
              <div id="license" className="d-flex gap-2 align-items-center">
                <p className="mb-0 fs-10">License:</p>
                <p
                  className="mb-0 fs-10 fw-semibold text-capitalize"
                  style={{
                    color: `${
                      handleCalculateLicenseStatus(company?.licenseScore ?? 0)
                        ?.colorText
                    }`,
                  }}
                >
                  {
                    handleCalculateLicenseStatus(company?.licenseScore ?? 0)
                      ?.message
                  }
                </p>
              </div>
            </>
          )}

          <div className="divide-dot mx-2" />
          <div id="traffic" className="d-flex gap-2 align-items-center">
            <p className="mb-0 fs-10">Traffic:</p>
            <p
              className="mb-0 fs-10 fw-semibold text-capitalize"
              style={{
                color: `${
                  handleCalculateTrafficStatus(company?.trafficScore ?? 0)
                    .textColor
                }`,
              }}
            >
              {handleCalculateTrafficStatus(company?.trafficScore ?? 0).message}
            </p>
          </div>
          {company?.socialLookUpScore !== null && (
            <>
              <div className="divide-dot mx-2" />
              <div
                id="social-lookup"
                className="d-flex gap-2 align-items-center"
              >
                <p className="mb-0 fs-10">Social look up:</p>
                <p
                  className="mb-0 fs-10 fw-semibold text-capitalize"
                  style={{
                    color: `${fnRatingDisplayColor(
                      company?.socialLookUpScore
                    )}`,
                  }}
                >
                  {fnRatingDisplay(company?.socialLookUpScore)}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="me-1" style={{ color: "#858693" }}>
            Powered by
          </div>
          <img src={logo} alt="logo-trust-finance" width={24} height={24} />
          <span className="fw-semibold">TrustFinance</span>
        </div>
      </a>{" "}
    </div>
  );
};

export default WidgetTemplate14;
