import starFull from '../assets/images/star-full.svg'
import starHalf from '../assets/images/star-half.svg';
import star from '../assets/images/star.svg';
import _ from 'lodash';
import { FC, useMemo } from 'react';

interface IStar {
  width: number;
  height: number;
}
const StarFull: FC<IStar> = ({ width = 24, height = 24 }) => (
  <img
    className="object-contain"
    style={{
      width,
      height,
    }}
    src={starFull}
    alt="star full"
  />
);

const StarHalf: FC<IStar> = ({ width = 24, height = 24 }) => (
  <img
    className="object-contain"
    style={{
      width,
      height,
    }}
    src={starHalf}
    alt="star half"
  />
);

const Star: FC<IStar> = ({ width = 24, height = 24 }) => (
  <img
    className="object-contain"
    style={{
      width,
      height,
    }}
    src={star}
    alt="star"
  />
);

const Rate = ({ score = 0, size = 24 }) => {
  const max = 5;
  const rate = useMemo(() => {
    let mod = (score % 1) * 10;
    const num = Math.floor(score);
    if (mod > 0) {
      if (mod < 5) mod = 0;
      else mod = 0.5;
    }
    return num + mod;
  }, [score]);

  return (
    <div className="d-flex gap-x-1">
      {_.times(max, (i) => {
        const x = i + 1;
        if (x <= rate)
          return (
            <StarFull key={i} width={size} height={size} />
          );
        if (x - rate === 0.5)
          return (
            <StarHalf key={i} width={size} height={size} />
          );
        return <Star key={i} width={size} height={size} />;
      })}
    </div>
  );
}

export default Rate;
