import { FC } from "react";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logo from "../../../assets/images/trustfinance-logo.svg";
import { ReachClick } from "../../../utils/core";

const WidgetTemplate10: FC<IWidgetTemplate> = ({
  company,
  score,
  review,
  theme,
}) => {
  const payload = {
    companyId: company?.data?.companyId,
    widgetId: 10,
  };
  return (
    <div className="overflow-hidden d-flex justify-content-center ">
      {" "}
      <a
        className="text-decoration-none"
        style={{ color: theme === "dark" ? "white" : "unset" }}
        href={`https://www.trustfinance.com/companies/${company?.data?.companyId}?utm_source=widget&utm_medium=banner&utm_campaign=review-collector`}
        target="_blank"
        rel="noreferrer noopener"
        onClick={() => ReachClick(payload)}
      >
        <div className="border border-primary d-flex mt-4">
          <div className="fs-16 d-flex align-items-center p-2 fw-bold">
            Reviews us on
          </div>
          <div className="d-flex align-items-center txt p-2">
            <img src={logo} alt="" width={24} height={24} />
            <span className="fs-16 ">TrustFinance</span>
          </div>
        </div>{" "}
      </a>
    </div>
  );
};

export default WidgetTemplate10;
