import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Page404 from "../error/404";
import Trustboxes from "./trustboxes";
import Widgets from "./widgets";

const ServeWidgets = () => {
  let [searchParams] = useSearchParams();
  let [state, setState] = useState<{
    templateId: number;
    businessId: string;
    theme: string;
    isTrustbox?: boolean;
    isError: boolean;
  }>({
    templateId: 1,
    businessId: "",
    theme: "",
    isTrustbox: undefined,
    isError: true,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      templateId: +(searchParams.get("templateId") ?? "1").replace(
        "trustbox_",
        ""
      ),
      businessId: searchParams.get("businessId") ?? "",
      theme: searchParams.get("theme") ?? "light",
      isTrustbox:
        searchParams.get("templateId")?.startsWith("trustbox_") ?? undefined,
      isError: searchParams.get("businessId") === "",
    }));
    // eslint-disable-next-line
  }, [searchParams]);

  if (state.isError) return <Page404 />;

  if (state.isTrustbox === undefined) return <Page404 />;

  if (state.isTrustbox) {
    return (
      <Trustboxes businessId={state.businessId} templateId={state.templateId} />
    );
  } else {
    return (
      <Widgets
        businessId={state.businessId}
        templateId={state.templateId}
        theme={state.theme}
      />
    );
  }
};

export default ServeWidgets;
