import axios, { Canceler } from "axios";

const CancelToken = axios.CancelToken;

const endpointDev = "https://api-dev.trustfinance.com";
const endpoint = "https://api.trustfinance.com";

const getDev = async (
  url = "",
  params = {},
  header = false,
  cancelToken = (c: Canceler) => {}
): Promise<any> => {
  let param = new URLSearchParams(document.location.search);

  let headers = {
    "company-id": header ? param.get("businessId") : null,
    "Content-Type": "application/json",
  };

  let uri = `${endpointDev}${url}`;
  if (url.startsWith("https://") || url.startsWith("http://")) {
    uri = url;
  }

  return axios({
    url: uri,
    method: "get",
    params,
    headers,
    cancelToken: new CancelToken(cancelToken),
  });
};

const get = async (
  url = "",
  params = {},
  header = false,
  cancelToken = (c: Canceler) => {}
): Promise<any> => {
  let param = new URLSearchParams(document.location.search);

  let headers = {
    "company-id": header ? param.get("businessId") : null,
    "Content-Type": "application/json",
  };

  let uri = `${endpoint}${url}`;
  if (url.startsWith("https://") || url.startsWith("http://")) {
    uri = url;
  }

  return axios({
    url: uri,
    method: "get",
    params,
    headers,
    cancelToken: new CancelToken(cancelToken),
  });
};

const post = async (
  url = "",
  data: object | FormData | null,
  cancelToken = (c: Canceler) => {}
): Promise<any> => {
  const typeFormData = new FormData().constructor;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: new CancelToken(cancelToken),
  };

  if (data?.constructor === typeFormData) {
    config["headers"] = {
      "Content-Type": "multipart/form-data",
    };
  }

  let uri = `${endpoint}${url}`;
  if (url.startsWith("https://") || url.startsWith("http://")) {
    uri = url;
  }
  return axios.post(uri, data, config);
};

const put = async (
  url = "",
  data = {},
  cancelToken = (c: Canceler) => {}
): Promise<any> => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: new CancelToken(cancelToken),
  };

  return axios.put(`${endpoint}${url}`, data, config);
};

const patch = async (
  url = "",
  data = {},
  cancelToken = (c: Canceler) => {}
): Promise<any> => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: new CancelToken(cancelToken),
  };

  let uri = `${endpoint}${url}`;
  if (url.startsWith("https://") || url.startsWith("http://")) {
    uri = url;
  }
  return axios.patch(uri, data, config);
};

const del = async (
  url = "",
  cancelToken = (c: Canceler) => {}
): Promise<any> => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: new CancelToken(cancelToken),
  };

  let uri = `${endpoint}${url}`;
  if (url.startsWith("https://") || url.startsWith("http://")) {
    uri = url;
  }
  return axios.delete(uri, config);
};

export const http = {
  getDev,
  get,
  post,
  put,
  patch,
  del,
};
