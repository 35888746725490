import { FC, useState } from "react";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logo from "../../../assets/images/trustfinance-logo.svg";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import { ReachClick } from "../../../utils/core";
import StarRate from "../../../components/StarRate";
import { fnRatingDisplay } from "../../../components/rateDisplay";

const WidgetTemplate9: FC<IWidgetTemplate> = ({
  company,
  score,
  review,
  theme,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const payload = {
    companyId: company?.data?.companyId,
    widgetId: 9,
  };
  return (
    <div className="cursor-pointer overflow-hidden d-flex justify-content-center align-items-center ">
      <div onClick={toggle}>
        <div>
          <StarRate value={score?.score} size={24} />
        </div>
      </div>
      <div
        onClick={toggle}
        className={`fs-16 d-flex align-items-center p-2 ${
          theme === "dark" ? "text-white" : ""
        }`}
      >
        See our reviews on
      </div>
      <Modal
        className={`${theme === "dark" ? "bg-dark text-white" : "bg-light"}`}
        isOpen={isOpen}
        toggle={toggle}
        fullscreen
      >
        <ModalHeader
          className={`d-flex align-items-center flex-column ${
            theme === "dark" ? "bg-dark text-white" : "bg-white"
          }`}
        >
          {" "}
          <div className="d-flex justify-content-center">
            <h4 className="me-2">{fnRatingDisplay(score?.score)}</h4>
            <StarRate value={score?.score} size={24} />
          </div>
          <div className="d-flex">
            <div className="my-2 text-nowrap">
              based on{" "}
              <a
                style={{ color: "unset" }}
                href={`https://www.trustfinance.com/companies/${company?.data?.companyId}?utm_source=widget&utm_medium=banner&utm_campaign=pop-up`}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => ReachClick(payload)}
              >
                {score?.totalReview} reviews
              </a>
            </div>
            <div className="d-flex align-items-center ms-2">
              <img src={logo} alt="" width={24} height={24} />
              <span style={{ fontSize: 14, marginLeft: 4, fontWeight: "bold" }}>
                TrustFinance
              </span>
            </div>
          </div>
        </ModalHeader>
        <ModalBody
          className={`${
            theme === "dark" ? "bg-dark" : "bg-white"
          } p-3  rounded`}
        >
          <div>
            <CardBody>
              <p>Showing our latest reviews</p>
              {review?.data?.map((item, i) => (
                <div
                  key={i}
                  className={`${
                    theme === "dark" ? "bg-secondary" : "bg-light"
                  } p-3 my-2 rounded`}
                >
                  <div>
                    <StarRate value={item.score} size={18} />
                    <h5 className="mt-2 text-break">{item.title}</h5>
                    <p className="fs-12 mb-2 text-break">{item.description}</p>
                    <div className="d-flex">
                      <span className="fs-12 fw-bold line-clamp-1 text-nowrap">
                        {item.user?.displayName}
                      </span>
                      <div className="fs-12 text-black-50 text-nowrap">
                        ,&nbsp;
                        {moment(item.createdAt).fromNow()}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </CardBody>
          </div>
        </ModalBody>
        <ModalFooter className={theme === "dark" ? "bg-dark" : "bg-white"}>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WidgetTemplate9;
