
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getCompanyByIdApi } from "../../apis/company";
import { ICompany } from "../../interfaces/company";
import Page404 from "../error/404";
import TrustboxTemplate1 from "./templates/trustbox-1";
import TrustboxTemplate2 from "./templates/trustbox-2";
import TrustboxTemplate3 from "./templates/trustbox-3";

const Trustboxes: FC<{ templateId: number, businessId: string }> = ({ templateId, businessId }) => {
  let [state, setState] = useState<{
    templateId: number;
    businessId: string;
    company: ICompany | undefined;
    isError: boolean;
  }>({
    templateId: templateId,
    businessId: businessId,
    company: undefined,
    isError: true,
  });


  const getCompanyById = async (id: string): Promise<void> => {
    try {
      const { data } = await getCompanyByIdApi(id);
      setState((prev) => ({
        ...prev,
        company: data,
        isError: false,
      }))
    } catch (e: any) {
      setState((prev) => ({
        ...prev,
        isError: true,
      }))
    }
  }

  useEffect(() => {
    getCompanyById(businessId);
    // eslint-disable-next-line
  }, [])


  if (state.isError) return <Page404 />

  switch (state.templateId) {
    case 1:
      return <TrustboxTemplate1 company={state.company!} />
    case 2:
      return <TrustboxTemplate2 company={state.company!} />
    case 3:
      return <TrustboxTemplate3 company={state.company!} />
    default:
      return <Page404 />
  }
}

export default Trustboxes;