import { FC } from "react";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logo from "../../../assets/images/trustfinance-logo.svg";
import { ReachClick } from "../../../utils/core";

const WidgetTemplate7: FC<IWidgetTemplate> = ({
  company,
  score,
  review,
  theme,
}) => {
  const payload = {
    companyId: company?.data?.companyId,
    widgetId: 7,
  };
  return (
    <a
      className="text-decoration-none"
      style={{ color: theme === "dark" ? "#c4c4c4" : "unset" }}
      href={`https://www.trustfinance.com/companies/${company?.data?.companyId}?utm_source=widget&utm_medium=banner&utm_campaign=micro-review-count`}
      target="_blank"
      rel="noreferrer noopener"
      onClick={() => ReachClick(payload)}
    >
      <div className="overflow-hidden d-flex justify-content-center  ">
        <div className="fs-16 d-flex align-items-center p-2">
          See our reviews on
        </div>
        <div className="d-flex align-items-center txt p-2">
          <img src={logo} alt="" width={19} height={19} />
          <span className="fs-16 ">TrustFinance</span>
        </div>
      </div>
    </a>
  );
};

export default WidgetTemplate7;
