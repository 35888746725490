import logo from "../../assets/images/trustfinance-logo.svg";

const Page404 = () => {
  return (
    <div className="error-page p-absolute" style={{
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    }}>
      <a className="text-decoration-none" href={"https://www.trustfinance.com/"} style={{ color: "unset" }} target="_blank" rel="noopener noreferrer">
        <div className="wrapper" style={{ padding: "18px 24px", display: 'flex', alignItems: "center" }}>
          <img src={logo} alt="" width={42} height={42} />
          <span style={{ fontSize: 32, marginLeft: 8, fontWeight: "bold" }}>TrustFinance</span>
        </div>
      </a>
    </div>
  )
}

export default Page404; 