import { FC } from "react";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logo from "../../../assets/images/trustfinance-logo.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import moment from "moment";
import { ReachClick } from "../../../utils/core";
import { fnRatingDisplay } from "../../../components/rateDisplay";
import StarRate from "../../../components/StarRate";
import "swiper/css";
import "swiper/css/navigation";
const WidgetTemplate1: FC<IWidgetTemplate> = ({
  company,
  score,
  review,
  theme,
}) => {
  const payload = {
    companyId: company?.data?.companyId,
    widgetId: 1,
  };

  return (
    <div
      className={`overflow-hidden d-flex justify-content-center row ${
        theme === "dark" ? "text-white" : ""
      }`}
    >
      <a
        className="text-decoration-none"
        style={{ color: "unset" }}
        href={`https://www.trustfinance.com/companies/${company?.data?.companyId}?utm_source=widget&utm_medium=banner&utm_campaign=carousel`}
        target="_blank"
        rel="noreferrer noopener"
        onClick={() => ReachClick(payload)}
      >
        <div className=" d-flex justify-content-center flex-column align-items-center">
          <h4>{fnRatingDisplay(score?.score)}</h4>
          <StarRate value={score?.score} size={32} />
          <div className="my-2">based on {score?.totalReview} reviews</div>
          <div className="d-flex align-items-center">
            <img src={logo} alt="" width={32} height={32} />
            <span style={{ fontSize: 18, marginLeft: 4, fontWeight: "bold" }}>
              TrustFinance
            </span>
          </div>
        </div>
        <div>
          <Swiper
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {review?.data?.map((item, i) => (
              <SwiperSlide key={i}>
                <div>
                  <StarRate value={item.score} size={18} />
                  <h5 className="text-review-widget mt-2">{item.title}</h5>
                  <p className="text-review-widget fs-12 mb-2">
                    {item.description}
                  </p>
                  <div className="d-flex">
                    <span className="fs-12 fw-bold text-nowrap line-clamp-1">
                      {item.user?.displayName}
                    </span>
                    <div
                      className={`fs-12 text-review-widget ${
                        theme === "dark" ? "text-white" : "text-black-50"
                      }`}
                    >
                      ,&nbsp;
                      {moment(item?.createdAt).fromNow()}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="p-2">Showing our latest reviews</div>
        </div>{" "}
      </a>
    </div>
  );
};

export default WidgetTemplate1;
