import { FC, useRef, useState } from "react";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logodark from "../../../assets/images/trusfinance-logo-dark.svg";
import { Collapse, CardBody, Card, CardHeader, CardFooter } from "reactstrap";

import StarRate from "../../../components/StarRate";
import { ReachClick } from "../../../utils/core";
import useClickOutside from "../../../hook/UseClickOutSide";
const WidgetTemplate3: FC<IWidgetTemplate> = ({ company, score, review }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rate, setRate] = useState(0);
  const [des, setDes] = useState("");
  const widgetRef = useRef(null);
  const payload = {
    companyId: company?.data?.companyId,
    widgetId: 3,
  };
  const handleOpenDropdown = (value: any) => {
    setRate(value);
    setIsOpen(true);
  };

  useClickOutside(widgetRef, () => {
    setIsOpen(false);
  });

  console.log("company :>> ", company);
  console.log("rate :>> ", rate);
  console.log("des :>> ", des);
  console.log(
    `https://www.trustfinance.com/review/invite?from=widget&companyId=${company.companyId}&score=${rate}&des=${des}&utm_source=widget&utm_medium=banner&utm_campaign=evaluate`
  );

  return (
    <div ref={widgetRef} className="container">
      <div>
        <div className="d-flex justify-content-center align-items-center cursor-pointer py-2 ">
          <div className="card ">
            <div className="card-header bg-primary">
              <div className="d-flex align-items-center ">
                <img
                  src={logodark}
                  alt=""
                  width={24}
                  height={24}
                  className="mr-2"
                />
                <span className="fs-18 text-white fw-bold pl-2">
                  TrustFinance
                </span>
              </div>
            </div>
            <div className="card-body">
              <h4>Rate your recent experience</h4>
              <div>
                <StarRate
                  value={rate}
                  selectable
                  onChange={handleOpenDropdown}
                />
              </div>
            </div>
          </div>
        </div>
        <Collapse isOpen={isOpen} horizontal={false}>
          <div className="d-flex justify-content-center align-items-center">
            <Card>
              <CardHeader className="d-flex align-items-center flex-column bg-white">
                <div className="d-flex">
                  <h4 className="me-2">Tell us about your experience</h4>
                </div>
                <a
                  href="https://www.trustfinance.com/legal/for-reviewers/recommendations-for-reviewers?utm_source=widget&utm_medium=banner&utm_campaign=evaluate"
                  target={"_blank"}
                >
                  Read our Guidelines for Reviewers
                </a>
              </CardHeader>
              <CardBody>
                <textarea
                  rows={5}
                  onChange={(e) => setDes(e.target.value)}
                  className="w-100"
                  placeholder="This is where you write your review. Explain what happened, and leave out offensive words. Keep your feedback honest, helpful, and constructive."
                />
                <a
                  href="https://www.trustfinance.com/legal/for-reviewers/recommendations-for-reviewers?utm_source=widget&utm_medium=banner&utm_campaign=evaluate"
                  target={"_blank"}
                >
                  How to write a useful review
                </a>
              </CardBody>
              <CardFooter>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    ReachClick(payload);
                    window.location.assign(
                      `https://www.trustfinance.com/review/invite?from=widget&companyId=${company.companyId}&score=${rate}&des=${des}&utm_source=widget&utm_medium=banner&utm_campaign=evaluate`
                    );
                  }}
                >
                  review
                </button>
              </CardFooter>
            </Card>
          </div>
        </Collapse>
      </div>
    </div>
    // </a>
  );
};

export default WidgetTemplate3;
