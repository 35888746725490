import { FC } from "react";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logo from "../../../assets/images/trusfinance-logo-dark.svg";
import { ReachClick } from "../../../utils/core";

const WidgetTemplate6: FC<IWidgetTemplate> = ({
  company,
  score,
  review,
  theme,
}) => {
  const payload = {
    companyId: company?.data?.companyId,
    widgetId: 6,
  };
  return (
    <a
      className={`text-decoration-none ${theme === "dark" ? "text-white" : ""}`}
      style={{ color: "unset" }}
      href={`https://www.trustfinance.com/companies/${company?.data?.companyId}?utm_source=widget&utm_medium=banner&utm_campaign=micro-button`}
      target="_blank"
      rel="noreferrer noopener"
      onClick={() => ReachClick(payload)}
    >
      <div className="overflow-hidden d-flex justify-content-center">
        <div
          className={`d-flex align-items-center border bg-primary p-2 ${
            theme === "dark" ? "border-secondary" : ""
          }`}
        >
          <img src={logo} alt="" width={19} height={19} />
          <span className="fs-11 text-white">TrustFinance</span>
        </div>
        <div
          className={`border border-start-0 fs-11 d-flex align-items-center p-2 ${
            theme === "dark" ? "border-secondary" : ""
          }`}
        >
          {score?.totalReview} Reviews
        </div>
      </div>
    </a>
  );
};

export default WidgetTemplate6;
