import { FC, useState } from "react";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logo from "../../../assets/images/trustfinance-logo.svg";
import { CardBody, CardHeader } from "reactstrap";

import moment from "moment";
import { ReachClick } from "../../../utils/core";
import { fnRatingDisplay } from "../../../components/rateDisplay";
import StarRate from "../../../components/StarRate";
const WidgetTemplate5: FC<IWidgetTemplate> = ({
  company,
  score,
  review,
  theme,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const payload = {
    companyId: company?.data?.companyId,
    widgetId: 5,
  };
  const toggle = () => setIsOpen(!isOpen);
  return (
    // <a className="text-decoration-none" style={{ color: "unset" }} href={`https://www.trustfinance.com/companies/${company.id}`} target="_blank" rel="noreferrer noopener">
    <div className="container">
      <div className="mt-4">
        <div>
          <CardHeader
            className={`p-2 rounded d-flex align-items-center flex-column ${
              theme === "dark" ? "bg-dark text-white" : "bg-white"
            }`}
          >
            <div className="d-flex">
              <h4 className="me-2">{fnRatingDisplay(score?.score)}</h4>
              <StarRate value={score?.score} size={24} />
            </div>
            <div className="d-flex">
              <div className="my-2">
                based on{" "}
                <a
                  style={{ color: "unset" }}
                  href={`https://www.trustfinance.com/companies/${company?.data?.companyId}?utm_source=widget&utm_medium=banner&utm_campaign=list`}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => ReachClick(payload)}
                >
                  {score?.totalReview} reviews
                </a>
              </div>
              <div className="d-flex align-items-center ms-2">
                <img src={logo} alt="" width={24} height={24} />
                <span
                  style={{ fontSize: 14, marginLeft: 4, fontWeight: "bold" }}
                >
                  TrustFinance
                </span>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <p className={`${theme === "dark" ? "text-white" : ""}`}>
              Showing our latest reviews
            </p>
            {review?.data?.map((item, i) => (
              <div
                key={i}
                className={`p-3 my-2 rounded ${
                  theme === "dark" ? "text-white bg-dark" : ""
                }`}
              >
                <div>
                  <StarRate value={item.score} size={18} />
                  <h5 className="mt-2">{item.title}</h5>
                  <p className="fs-12 mb-2">{item.description}</p>
                  <div className="d-flex">
                    <span className="fs-12 fw-bold line-clamp-1 text-nowrap">
                      {item.user?.displayName}
                    </span>
                    <div
                      className={`fs-12 text-nowrap  ${
                        theme === "dark" ? "text-secondary" : "text-black-50"
                      }`}
                    >
                      ,&nbsp;
                      {moment(item.createdAt).fromNow()}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </CardBody>
        </div>
      </div>
    </div>
    // </a>
  );
};

export default WidgetTemplate5;
