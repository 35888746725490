import { FC } from "react";
import { ITrustboxTemplate } from "../../../interfaces/trustbox-template";
import logo from "../../../assets/images/trustfinance-logo.svg";

const TrustboxTemplate1: FC<ITrustboxTemplate> = ({ company }) => {
  return (
    <a className="text-decoration-none" style={{ color: "unset" }} href={`https://www.trustfinance.com/companies/${company.id}`} target="_blank" rel="noreferrer noopener">
      <div className="overflow-hidden d-flex flex-column align-items-center">
        <div className="text-center">
          <div style={{ fontSize: 11 }}>See our <b>{company.rate.totalReview}</b></div>
          <div style={{ fontSize: 11 }}>reviews on</div>
        </div>
        <div className="d-flex align-items-center">
          <img src={logo} alt="" width={19} height={19} />
          <span style={{ fontSize: 11, marginLeft: 4, fontWeight: "bold" }}>TrustFinance</span>
        </div>
      </div>
    </a>
  )
}

export default TrustboxTemplate1;