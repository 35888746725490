import { FC, useEffect, useState } from "react";
import {
  getReviewByCompanyIdApi,
  getScoreByIdApi,
  getCompanyInfoByIdApi,
  getCompanyInfoV2Api,
} from "../../apis/company";
import { ICompanyInfo } from "../../interfaces/company";
import { IReview } from "../../interfaces/review";
import { IScore } from "../../interfaces/score";
import { ReachView } from "../../utils/core";
import Page404 from "../error/404";
import WidgetTemplate1 from "./website/widget-1";
import WidgetTemplate11 from "./website/widget-11";
import WidgetTemplate2 from "./website/widget-2";
import WidgetTemplate3 from "./website/widget-3";
import WidgetTemplate4 from "./website/widget-4";
import WidgetTemplate5 from "./website/widget-5";
import WidgetTemplate6 from "./website/widget-6";
import WidgetTemplate7 from "./website/widget-7";
import WidgetTemplate9 from "./website/widget-9";
import WidgetTemplate10 from "./website/widget-10";
import WidgetTemplate8 from "./website/widget-8";
import WidgetTemplate12 from "./website/widget-12";
import WidgetTemplate13 from "./website/widget-13";
import WidgetTemplate14 from "./website/widget-14";
import { ICompanyType } from "../../interfaces/companyV2";

const Widgets: FC<{
  templateId: number;
  businessId: string;
  theme: string;
}> = ({ templateId, businessId, theme }) => {
  let [state, setState] = useState<{
    templateId: number;
    businessId: string;
    theme: string;
    company: (ICompanyInfo & ICompanyType) | undefined;
    score: IScore | undefined;
    review: IReview | undefined;
    isError: boolean;
  }>({
    templateId: templateId,
    businessId: businessId,
    theme: theme,
    company: undefined,
    score: undefined,
    review: undefined,
    isError: true,
  });

  const getCompanyInfoById = async (id: string): Promise<void> => {
    try {
      let companyDetail: any;
      if (state.templateId === 14) {
        const { data } = await getCompanyInfoV2Api();
        companyDetail = data;
      } else {
        const { data } = await getCompanyInfoByIdApi(id);
        companyDetail = data;
      }
      setState((prev) => ({
        ...prev,
        company: companyDetail,
        isError: false,
      }));
    } catch (e: any) {
      throw e;
    }
  };

  const getScoreById = async (id: string): Promise<void> => {
    try {
      const { data } = await getScoreByIdApi(id);

      setState((prev) => ({
        ...prev,
        score: data,
      }));
    } catch (e: any) {
      throw e;
    }
  };

  const getRevewById = async (id: string): Promise<void> => {
    try {
      const { data } = await getReviewByCompanyIdApi(id);

      setState((prev) => ({
        ...prev,
        review: data,
      }));
    } catch (e: any) {
      throw e;
    }
  };

  const initData = async (id: string) => {
    try {
      await getCompanyInfoById(businessId);
      if (state.templateId !== 14) {
        await Promise.all([getScoreById(businessId), getRevewById(businessId)]);
      }
    } catch (error) {
      setState((prev) => ({
        ...prev,
        isError: true,
      }));
    }
  };
  useEffect(() => {
    initData(businessId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!state.isError) {
      ReachView({ companyId: state.businessId, widgetId: state.templateId });
    }
    // eslint-disable-next-line
  }, [state.isError]);

  if (state.isError) return <Page404 />;

  switch (state.templateId) {
    case 1:
      return (
        <WidgetTemplate1
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 2:
      return (
        <WidgetTemplate2
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 3:
      return (
        <WidgetTemplate3
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 4:
      return (
        <WidgetTemplate4
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 5:
      return (
        <WidgetTemplate5
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 6:
      return (
        <WidgetTemplate6
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 7:
      return (
        <WidgetTemplate7
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 8:
      return (
        <WidgetTemplate8
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 9:
      return (
        <WidgetTemplate9
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 10:
      return (
        <WidgetTemplate10
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 11:
      return (
        <WidgetTemplate11
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 12:
      return (
        <WidgetTemplate12
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 13:
      return (
        <WidgetTemplate13
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    case 14:
      return (
        <WidgetTemplate14
          company={state.company!}
          score={state.score!}
          review={state.review!}
          theme={state.theme}
        />
      );
    default:
      return <Page404 />;
  }
};

export default Widgets;
