import { FC, useState } from "react";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logo from "../../../assets/images/trustfinance-logo.svg";
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";

import moment from "moment";
import { ReachClick } from "../../../utils/core";
import { fnRatingDisplay } from "../../../components/rateDisplay";
import StarRate from "../../../components/StarRate";
const WidgetTemplate2: FC<IWidgetTemplate> = ({
  company,
  score,
  review,
  theme,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const payload = {
    companyId: company?.data?.companyId,
    widgetId: 2,
  };
  const toggle = () => setIsOpen(!isOpen);
  return (
    // <a className="text-decoration-none" style={{ color: "unset" }} href={`https://www.trustfinance.com/companies/${company.id}`} target="_blank" rel="noreferrer noopener">
    <div className="container">
      <div>
        <div
          className={`d-flex justify-content-center align-items-center cursor-pointer py-2 ${
            theme === "dark" ? "text-white" : ""
          }`}
          onClick={() => toggle()}
        >
          <StarRate value={score?.score} size={24} />{" "}
          <h6 className="mb-0 ms-2">
            See our reviws <i className="fa fa-caret-down"></i>
          </h6>
        </div>
        <Collapse isOpen={isOpen} horizontal={false}>
          <Card>
            <CardHeader className="d-flex align-items-center flex-column bg-white">
              <div className="d-flex">
                <h4 className="me-2">{fnRatingDisplay(score?.score)}</h4>
                <StarRate value={score?.score} size={24} />
              </div>
              <div className="d-flex">
                <div className="my-2">
                  based on{" "}
                  <a
                    style={{ color: "unset" }}
                    href={`https://www.trustfinance.com/companies/${company?.data?.companyId}?utm_source=widget&utm_medium=banner&utm_campaign=drop-down`}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() => ReachClick(payload)}
                  >
                    {score?.totalReview} reviews
                  </a>
                </div>
                <div className="d-flex align-items-center ms-2">
                  <img src={logo} alt="" width={24} height={24} />
                  <span
                    style={{ fontSize: 14, marginLeft: 4, fontWeight: "bold" }}
                  >
                    TrustFinance
                  </span>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <p>Showing our latest reviews</p>
              {review?.data?.map((item, i) => (
                <div key={i} className="bg-light bg-gradient p-3 my-2 rounded">
                  <div>
                    <StarRate value={item.score} size={18} />
                    <h5 className="mt-2">{item.title}</h5>
                    <p className="fs-12 mb-2">{item.description}</p>
                    <div className="d-flex">
                      <span className="fs-12 fw-bold line-clamp-1 text-nowrap">
                        {item.user?.displayName}
                      </span>
                      <div className="fs-12 text-black-50 text-nowrap">
                        ,&nbsp;
                        {moment(item?.createdAt).fromNow()}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </div>
    // </a>
  );
};

export default WidgetTemplate2;
