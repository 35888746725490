import { Route, Routes } from 'react-router-dom';
import Page404 from './pages/error/404';
import ServeWidgets from './pages/widget';
function App() {
  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}/widgets`} element={<ServeWidgets />}>
        <Route index element={<ServeWidgets />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default App;
