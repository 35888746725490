import { FC, useState } from "react";
import { IWidgetTemplate } from "../../../interfaces/widget-template";
import logo from "../../../assets/images/trustfinance-logo.svg";
import { Tooltip } from "reactstrap";
import { ReachClick } from "../../../utils/core";
import StarRate from "../../../components/StarRate";

const WidgetTemplate11: FC<IWidgetTemplate> = ({
  company,
  score,
  review,
  theme,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const payload = {
    companyId: company?.data?.companyId,
    widgetId: 11,
  };
  return (
    <div className="overflow-hidden d-flex justify-content-center ">
      <a
        className="text-decoration-none"
        style={{ color: theme === "dark" ? "white" : "unset" }}
        href={`https://www.trustfinance.com/companies/${company?.data?.companyId}?utm_source=widget&utm_medium=banner&utm_campaign=starter`}
        target="_blank"
        rel="noreferrer noopener"
        id={"Tooltip-widget11"}
        onClick={() => ReachClick(payload)}
      >
        <div className=" review-score ">
          <div>
            Check out our <b>{score?.totalReview} reviews</b>
          </div>
          <div className="fs-16 d-flex align-items-center p-2 fw-bold">
            <StarRate value={score?.score} size={24} />
          </div>
          <div className="d-flex align-items-center txt p-2">
            <img src={logo} alt="" width={24} height={24} />
            <span className="fs-16 ">TrustFinance</span>
          </div>
        </div>{" "}
      </a>{" "}
      <Tooltip
        placement={"bottom"}
        isOpen={tooltipOpen}
        target={"Tooltip-widget11"}
        autohide={false}
        toggle={toggle}
        style={{ backgroundColor: theme === "dark" ? "white" : "black" }}
        hideArrow
      >
        <div style={{ color: theme === "dark" ? "black" : "white" }}>
          Helping each other make better choices.{" "}
          <a
            target={"_blank"}
            href={`https://www.trustfinance.com/legal/for-reviewers/recommendations-for-reviewers`}
          >
            Read and write reviews
          </a>
        </div>
      </Tooltip>
    </div>
  );
};

export default WidgetTemplate11;
