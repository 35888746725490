import { useEffect, useState } from "react";
interface StarRateProps {
  star: number;
}
const RatingDisplay = ({ star }: StarRateProps) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    switch (true) {
      case star >= 0.0 && star < 1.4:
        setMessage("Bad");
        break;
      case star >= 1.5 && star < 2.4:
        setMessage("Poor");
        break;
      case star >= 2.5 && star < 3.4:
        setMessage("Average");
        break;
      case star >= 3.5 && star < 4.4:
        setMessage("Great");
        break;
      case star >= 4.5 && star <= 5.0:
        setMessage("Excellent");
        break;
      default:
        setMessage("Invalid Rating");
    }
  }, [star]);
  return message;
};
export default RatingDisplay;
export const fnRatingDisplay = (star: number) => {
  switch (true) {
    case star >= 0.1 && star < 1.49:
      return "Bad";
    case star >= 1.5 && star < 2.49:
      return "Poor";
    case star >= 2.5 && star < 3.49:
      return "Average";
    case star >= 3.5 && star < 4.49:
      return "Great";
    case star >= 4.5 && star <= 5.0:
      return "Excellent";
    default:
      return "is waiting for you";
  }
};

export const fnRatingDisplayColor = (star: number) => {
  switch (true) {
    case star >= 0.1 && star < 1.49:
      return "#FF4D5B";
    case star >= 1.5 && star < 2.49:
      return "#FF9B45";
    case star >= 2.5 && star < 3.49:
      return "#FFBB00";
    case star >= 3.5 && star < 4.49:
      return "#5596FF";
    case star >= 4.5 && star <= 5.0:
      return "#4060FF";
    default:
      return "";
  }
};
