import { http } from "./http"

const ReachView = async (payload: { companyId: string, widgetId: number }) => {
  try {
    await http.post("/widgets/reach/views", payload)
  } catch (error) {
  }
}

const ReachClick = async (payload: { companyId: string, widgetId: number }) => {
  try {
    await http.post("/widgets/reach/clicks", payload)
  } catch (error) {
  }
}

export {
  ReachView,
  ReachClick
}